import React from "react";

export const RawMaterial = React.lazy(() => import("./RawMaterial"));
export const RawMaterialNew = React.lazy(() => import("./RawMaterialNew"));
export const RawMaterialEdit = React.lazy(() => import("./RawMaterialEdit"));

export const BillOfMaterial = React.lazy(() => import("./BillOfMaterial"));
export const BillOfMaterialNew = React.lazy(() =>
  import("./BillOfMaterialNew")
);
export const BillOfMaterialEdit = React.lazy(() =>
  import("./BillOfMaterialEdit")
);

export const ProductionOrder = React.lazy(() => import("./ProductionOrder"));

export const ProductionOrderStart = React.lazy(() =>
  import("./ProductionOrderStart")
);

export const ProductionOrderEnd = React.lazy(() =>
  import("./ProductionOrderEnd")
);
